import React from "react";
import Posts from "../components/Posts";
import circleSvg from "../images/Circle.svg";
import headerImgCarr from "../images/10011.webp";

const Careers = () => {
  return (
    <>
      {/* Header */}
      <div className="container-fluid py-4 py-md-5 light-bg overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5 col-lg-6 py-3">
              <div className="pt-lg-5">
                <h1 className="cl-blue-900 mt-3">
                  We hired people who are very passionate about what they do
                </h1>
                <p className="my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique
                </p>
              </div>
              <div className="mt-4">
                <a
                  href="#openPositionsInAgency"
                  type="button"
                  className="btn btn-primary my-2 bg-brand-primay"
                >
                  View Positions
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-6 position-relative">
              <div className="img-bar"></div>
              <img
                src={circleSvg}
                alt=""
                className="overlay-img--circle p-ru position-absolute"
              />
              <img
                className="img-fit-cover b-6"
                src={headerImgCarr}
                alt=""
                srcSet=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* Career section */}
      <section
        id="openPositionsInAgency"
        className="container-fluid light-bg py-5 mb-5"
      >
        <div className="container">
          <h2>See our open positions</h2>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mt-4 g-4">
            <div className="col b-6">
              <div className="card bg-bluefaint border-0 b-6">
                <div className="card-body">
                  <h5 className="card-title">Full Stack Developer</h5>
                  <p className="card-text">Bengaluru · Full Time</p>
                  <a href="/apply" className="card-link">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col b-6">
              <div className="card bg-bluefaint border-0 b-6">
                <div className="card-body">
                  <h5 className="card-title">Testing Engineer</h5>
                  <p className="card-text">Bengaluru · Full Time</p>
                  <a href="/apply" className="card-link">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col b-6">
              <div className="card bg-bluefaint border-0 b-6">
                <div className="card-body">
                  <h5 className="card-title">Hr Manager</h5>
                  <p className="card-text">Mumbai · Full Time</p>
                  <a href="/apply" className="card-link">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col b-6">
              <div className="card bg-bluefaint border-0 b-6">
                <div className="card-body">
                  <h5 className="card-title">Full Stack Developer</h5>
                  <p className="card-text">Bengaluru · Full Time</p>
                  <a href="/apply" className="card-link">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col b-6">
              <div className="card bg-bluefaint border-0 b-6">
                <div className="card-body">
                  <h5 className="card-title">Testing Engineer</h5>
                  <p className="card-text">Bengaluru · Full Time</p>
                  <a href="/apply" className="card-link">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Latest Section */}
      <section
        data-posts-category="latestBlogAndNews"
        className="container-fluid py-5 bg-bluelight light-bg"
      >
        <Posts />
      </section>
    </>
  );
};

export default Careers;
