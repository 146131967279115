// src/pages/Blog.js
import React from "react";
import personImg from "../images/person-img.png";
import headerImgBlog from "../images/10002.webp";
import Posts from "../components/Posts"; // Import the Posts component

const Blog = () => {
  return (
    <div className="app">
      {/* Header */}
      <div className="container-fluid py-4 py-md-5 bg-bluelight light-bg">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5 col-lg-6 py-3">
              <div className="pt-lg-5">
                <figcaption className="reviewer-info d-flex align-items-center">
                  <img
                    className="rounded-circle"
                    src={personImg}
                    alt="Reviewer"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div className="d-flex flex-wrap">
                    <span className="px-2 fs-7">Nikita</span>
                    <span className="px-2 fs-7">
                      Posted on 27th January 2024
                    </span>
                  </div>
                </figcaption>
                <h1 className="cl-blue-900 mt-3">
                  Our internal process and longterm vision
                </h1>
                <p className="my-4">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The Maker is a decentralized. We aim to attain the
                </p>
              </div>
              <a href="./BlogInner.html" className="my-2">
                Read More <i className="bi bi-arrow-right"></i>
              </a>
            </div>
            <div className="col-md-7 col-lg-6 position-relative">
              <img
                className="img-fit-cover b-6"
                src={headerImgBlog}
                alt="Blog Header"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Latest Section */}
      <section
        data-posts-category="helpPosts"
        className="container-fluid py-5 light-bg"
      >
        <div className="container">
          <h2 className="cl-blue-900">Latest Posts</h2>
          {/* Call the Posts component with different categories */}
          <Posts category="helpPosts" />
          <Posts category="latestBlogAndNews" />
          {/* You can add more categories as needed */}
        </div>
      </section>
    </div>
  );
};

export default Blog;
